import styled from "@emotion/styled";
import { css } from "@emotion/core";

export const disabledColor = "rgba(58, 59, 62, 0.5)";

export interface SectionProps {
    active?: boolean;
    standalone?: boolean;
}

export const Section = styled.section<SectionProps>`
    display: block;
    max-width: 482px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;

    box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.active ? "#ffffff" : "#26282a"};

    padding: 24px;

    margin: auto;
    margin-bottom: ${props => props.standalone ? "0" : "16px"};
    margin-top: ${props => props.standalone ? "48px" : "0"};
`;

export const Container = styled.main`
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
`;


export const Text = styled.span<{disabled?: boolean}>`
    font-family: Barlow, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: ${props => props.disabled ? "#757575" : "#111213"};
    margin: 8px 0;
`;

export const BoldText = styled(Text)`
    font-weight: bold;
`;

export const linkButtonStyle = css`
    text-decoration: none;
    background: none;
    border: none;
    outline: none;
    font-family: Barlow, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;

    :hover {
        cursor: pointer;
    }

    :hover, :active, :focus {
        color: #12b587;
        outline: none;
    }
`;

export const baseTextStyle = css`
    font-family: Barlow, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #111213;
`;

export const Header = styled.h2<{disabled?: boolean}>`
    margin: 0;
    padding: 0;
    font-family: Barlow;
    font-size: 21px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${props => props.disabled ? "#757575" : "#111213"};
`;

export const Error = styled.div`
    ${baseTextStyle};
    color: #fc172f;
    font-size: 14px;
`;

export const Margin = styled.div`
    margin: 16px 0;
`;