import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useConfig } from "@fitplan/context/lib-es/config";
import { Input } from "@fitplan/lib/components/Input";
import { WebImage } from "@fitplan/lib/components/WebImage";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { IGiftCard } from "@fitplan/lib/types/stripe";
import { FitplanResult } from "@fitplan/lib/types/api";
import axios from "axios";
import { sentryReportException } from "@fitplan/lib/utils";

interface Props {
    onVerified: (giftcard: IGiftCard) => void;
}

export const VerifyGiftCard: React.FunctionComponent<Props> = ({ onVerified, ...props }) => {
    const { imageBaseUrl, apiBaseUrl } = useConfig();
    const [code, setCode] = React.useState("");
    const [error, setError] = React.useState("");
    const [pending, setPending] = React.useState(false);

    return (
        <BoxWrapper>
            <Form
                onSubmit={async (event) => {
                    event.preventDefault();
                    setPending(true);
                    try {
                        const result = await axios.get<FitplanResult<IGiftCard>>(`${apiBaseUrl}/v1/gift-card/validate/${code}`);
                        setPending(false);
                        if (result?.data?.error) {
                            setError("Could not validate gift card");
                        } else if (result?.data?.result?.canBeRedeemed) {
                            onVerified(result.data.result);
                        } else if (result?.data?.result?.canBeRedeemed === false) {
                            setError("This gift card code either does not exist or has already been used");
                        }
                    } catch(err) {
                        sentryReportException(err);
                        setError("An unknown error occurred. Please try again later.");
                    }
                }}
            >
                <WebImage
                    css={css`
                        display: block;
                        margin: auto;
                        border-radius: 5px;
                        width: 260px;
                        margin-bottom: 24px;
                    `}
                    src={`${imageBaseUrl}/web/giftcard/bow-card.png`}
                    transformation={{width: 260}}
                />
                <CodeInput
                    type="text"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    placeholder="GIFT CODE"
                />
                {error && <Error>{error}</Error>}
                <Submit
                    type="submit"
                    disabled={pending || code.length < 5}
                >Redeem Gift Card</Submit>
            </Form>
        </BoxWrapper>
    );
};

const cssBreakpoint = "457px;"

const BoxWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;

    @media screen and (min-width: ${cssBreakpoint}) {
        padding:0;
    }
`;

const Form = styled.form`
    display: block;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 20px auto;
    margin-bottom: 24px;
    padding: 45px 16px;
    width: 100%;
    box-sizing: border-box;
    max-width: 434px;

    @media screen and (min-width: ${cssBreakpoint}) {
        margin: 80px auto;
        width: 434px;
        padding: 56px 24px;
    }
`;

const CodeInput = styled(Input)`
    border-radius: 5px;
    border: solid 2px #aaaaaa;
    margin-bottom: 24px;
    padding: 8px;
    text-align: center;

    :focus {
        border: solid 2px #aaaaaa;
    }
`;

const Submit = styled(BrandButton)`
    height: 60px;
    border-radius: 30px;
    text-transform: uppercase;
    min-width: 300px;
    margin: auto;
`;

const Error = styled.div`
    color: #fc172f;
    text-align: center;
    margin-bottom: 24px;
`;
