import * as React from "react";

import { Polygon } from "../Chevron/Polygon";
import { Link } from "../Link";
import { WebImage } from "../WebImage";

import "./ChevronBanner.scss";

export interface Props {
    src: string;
    mobileSrc?: string;
    title: string;
    subtitle: string;
    button: string;
    linkTo?: string;
    branchLink?: string;
    polygonColor?: string;
    mobilePolygonColor?: string;
    onClick?: () => void;
}

export const ChevronBanner: React.SFC<Props> = ({
    src,
    subtitle,
    title,
    linkTo,
    branchLink,
    button,
    polygonColor,
    mobileSrc,
    onClick,
    mobilePolygonColor,
    ...props
}) => {
    return (
        <div className="chevron__banner-container">
            <div className="chevron__banner-web">
                <WebImage src={src} style={{ width: "100%" }} />
                <div className="chevron__banner-overlay">
                    <div className="chevron__banner-text">
                        <h4 className="chevron__banner-subtitle">{subtitle}</h4>
                        <div className="chevron__banner-title">{title}</div>
                        {linkTo || branchLink ? (
                            <Link to={linkTo} branchLink={branchLink}>
                                <button className="chevron__banner-button" style={{ width: "220px" }}>
                                    {button}
                                </button>
                            </Link>
                        ) : (
                            onClick && <button
                                className="chevron__banner-button"
                                style={{ width: "220px" }}
                                onClick={onClick}
                            >
                                {button}
                            </button>
                        )}
                    </div>
                </div>
                <div className="chevron__banner-polygon">
                    <Polygon polygonColor={polygonColor || "white"} />
                </div>
            </div>
            <div className="chevron__banner-mobile">
                <div className="chevron__image-crop">
                    <div className="chevron__image">
                        <WebImage src={mobileSrc || src} style={{ width: "100%" }} />
                    </div>
                    <div className="chevron__banner-polygon-mobile">
                        <Polygon polygonColor={mobilePolygonColor || "#111213"} />
                    </div>
                </div>
                <div className="chevron__banner-text-mobile">
                    <h4 className="chevron__banner-subtitle-mobile">{subtitle}</h4>
                    <div className="chevron__banner-title-mobile">{title}</div>
                    {linkTo || branchLink ? (
                        <Link to={linkTo} branchLink={branchLink}>
                            <button
                                className="chevron__banner-button-mobile"
                                style={{ width: "220px" }}
                            >
                                {button}
                            </button>
                        </Link>
                    ) : (
                        onClick && <button
                            className="chevron__banner-button-mobile"
                            style={{ width: "220px" }}
                            onClick={onClick}
                        >
                            {button}
                        </button>
                    )}
                </div>
                <div className="chevron__banner-polygon">
                    <Polygon polygonColor={polygonColor || "white"} />
                </div>
            </div>
        </div>
    );
};