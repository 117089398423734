import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useConfig } from "@fitplan/context/lib-es/config";

import { WebImage } from "../WebImage";

export interface Props {
    name: string;
    email: string;
    avatarUrl: string;
}

export const UserView: React.FunctionComponent<Props> = ({ name, email, avatarUrl, ...props }) => {
    const { imageBaseUrl } = useConfig();
    return (
        <Container>
            <Image>
                <object
                    data={`${imageBaseUrl}/web/subscribe/icons/default-profile.png`}
                    type="image/png"
                    width="40px"
                    height="40px"
                >
                    <WebImage
                        src={avatarUrl}
                        alt="profile image"
                        transformation={{ width: 40, height: 40 }}
                        css={css`
                            width: 100%;
                        `}
                    />
                </object>
            </Image>
            <WebImage
                src={`${imageBaseUrl}/web/subscribe/icons/check-bold.png`}
                alt="green check mark"
                transformation={{ width: 16 }}
                css={css`
                    grid-area: icon;
                `}
            />
            <Name>{name}</Name>
            <Email>{email}</Email>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    display: grid;
    grid-template-areas:
        'avatar name icon'
        'avatar email email';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto 1fr auto;

    align-items: center;
`;

const Image = styled.div`
    grid-area: avatar;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    overflow: hidden;
`;

const Name = styled.div`
    grid-area: name;

    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
`;

const Email = styled(Name)`
    grid-area: email;
`;
