/** @jsx jsx */
import * as React from "react";
import { jsx, css } from "@emotion/core";
import { gtagPageView } from "../../analytics";

import { Link } from "../Link";
import { StartFistIcon } from "./StartFistIcon";
import { BrandLink } from "../Button/BrandButton";
import { useConfig } from "@fitplan/context/lib-es/config";
import useMount from "react-use/lib/useMount";
import { useTatari } from "../../analytics/useTatari";
import { useStrings } from "@fitplan/context/lib-es/strings";

export interface DownloadStrings {
    success: string;
    button: string;
    exit: string;
}

export const StartPage: React.FunctionComponent<{}> = (props) => {
    const { isProduction } = useConfig();
    const strings = useStrings("startPage");
    const {tatariTrack} = useTatari();
    useMount(() => {
        gtagPageView("/account/subscribe/complete", undefined, isProduction);
        tatariTrack("pageview", { path: "/account/subscribe/complete" });
    });

    return (
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <StartFistIcon />
                <h1
                    css={css`
                        font-family: 'Barlow Condensed', sans-serif;
                        font-size: 32px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: condensed;
                        line-height: 1.25;
                        letter-spacing: normal;
                        text-align: center;
                        color: #000000;
                        margin: 0 auto 24px auto;
                    `}
                >
                    {strings.success}
                </h1>

                <BrandLink href="https://fitplan.app.link/downloadapp">
                    {strings.button.toUpperCase()}
                </BrandLink>
                <Link
                    css={css`
                        font-size: 18px;
                        color: #12b587;
                        text-decoration: none;
                        margin: auto;
                        text-align: center;
                        margin-top: 20px;
                    `}
                    to="/account"
                >
                    {strings.exit}
                </Link>
            </div>
    );
};
