import * as React from "react";
import styled from "@emotion/styled";
import { baseTextStyle } from "./elements";

export interface Props {
    title: string;
    subtitle: string;
}

export const PlanView: React.FunctionComponent<Props> = ({ title, subtitle, ...props }) => {
    return (
        <Container>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
`;

const Title = styled.div`
    grid-area: title;

    ${baseTextStyle};
    font-weight: bold;
    color: #ffffff;
`;

const Subtitle = styled(Title)`
    grid-area: subtitle;

    font-size: 14px;
    font-weight: normal;
    line-height: 1.29;
    letter-spacing: 2px;
    text-transform: uppercase;
`;
