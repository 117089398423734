import * as React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Link } from "../Link";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTatari } from "../../analytics/useTatari";
import { IUser } from "@fitplan/context/lib-es/auth/types";

import { SignUpStrings, SignUpController } from "./SignUpController";
import {
    Row,
    SeparatorRow,
    Separator,
    SeparatorText,
    ErrorRow,
    TermsRow,
    ResponsiveInput,
    FormRow,
    RadioGroup,
    InputRow,
    SignUpContainer,
    SignUpRow,
    AppleSignInContainer,
} from "./layout";
import { SSOButton, LoginButton } from "./Button";
import { GoogleIcon, FacebookIcon } from "./icons";
import { useStrings } from "@fitplan/context/lib-es/strings";

export interface Props {
    disabled?: boolean;
    darkMode?: boolean;
    partner?: string;
    onComplete: (user: IUser) => void;
    redirectUri?: string;
}

export const SignUpForm: React.SFC<Props> = ({
    redirectUri,
    disabled,
    darkMode,
    onComplete,
    partner,
}) => {
    const config = useConfig();
    const { tatariIdentify, tatariTrack } = useTatari();
    const strings = useStrings<SignUpFormStrings>("signUpPage");

    return (
        <SignUpController
            partner={partner}
            onComplete={user => {
                tatariIdentify(user.userId);
                tatariTrack("signup", { userId: user.userId });
                onComplete(user);
            }}
            redirectUri={redirectUri}
        >
            {({
                globalError,
                firstName,
                lastName,
                email,
                password,
                confirmPassword,
                gender,
                onSubmit,
                isLoading,
                submitDisabled,
                facebookSignUp,
                googleSignUp,
                emailCheckbox,
            }) => (
                <SignUpContainer darkMode={darkMode} onSubmit={onSubmit}>
                    <Row>
                        <FacebookLogin
                            appId={config.facebookAppId}
                            autoLoad={false}
                            disableMobileRedirect={true}
                            fields="email"
                            authType={"reauthenticate"}
                            render={(innerProps: any) => (
                                <SSOButton
                                    colors="dark"
                                    icon={<FacebookIcon />}
                                    onClick={innerProps.onClick}
                                    disabled={disabled}
                                >
                                    {strings.facebookButton}
                                </SSOButton>
                            )}
                            {...facebookSignUp}
                        />
                        <GoogleLogin
                            clientId={config.googleApiClientId}
                            cookiePolicy={"single_host_origin"}
                            render={innerProps => (
                                <SSOButton
                                    colors="light"
                                    icon={<GoogleIcon />}
                                    onClick={innerProps.onClick}
                                    disabled={disabled}
                                >
                                    {strings.googleButton}
                                </SSOButton>
                            )}
                            {...googleSignUp}
                        />
                        <AppleSignInContainer
                            id="appleid-signin"
                            data-color="black"
                            data-border="true"
                            data-type="sign in"
                        />
                    </Row>
                    <SeparatorRow>
                        <Separator />
                        <SeparatorText>{strings.spearatorText}</SeparatorText>
                        <Separator />
                    </SeparatorRow>
                    <InputRow>
                        <ResponsiveInput
                            darkMode={darkMode}
                            mobileOrder={1}
                            order={1}
                            name="firstName"
                            type="text"
                            {...firstName}
                        />
                        <FormRow mobileOrder={3} order={2}>
                            <RadioGroup>
                                <input type="radio" {...gender.male} />
                                <label>{strings.radioMaleText}</label>
                            </RadioGroup>
                            <RadioGroup>
                                <input type="radio" {...gender.female} />
                                <label>{strings.radioFemaleText}</label>
                            </RadioGroup>
                            <RadioGroup>
                                <input type="radio" {...gender.other} />
                                <label>{strings.radioOtherText}</label>
                            </RadioGroup>
                        </FormRow>
                        <ResponsiveInput
                            darkMode={darkMode}
                            mobileOrder={2}
                            order={3}
                            name="lastName"
                            type="text"
                            {...lastName}
                        />
                        <ResponsiveInput
                            darkMode={darkMode}
                            mobileOrder={5}
                            order={4}
                            name="password"
                            type="password"
                            {...password}
                        />
                        <ResponsiveInput
                            darkMode={darkMode}
                            mobileOrder={4}
                            order={5}
                            name="username"
                            type="email"
                            {...email}
                        />
                        <ResponsiveInput
                            darkMode={darkMode}
                            mobileOrder={6}
                            order={6}
                            name="confirmPassword"
                            type="password"
                            {...confirmPassword}
                        />
                    </InputRow>
                    <ErrorRow>{globalError}</ErrorRow>
                    <LoginButton
                        disabled={submitDisabled}
                        type="submit"
                        onClick={onSubmit}
                    >
                        {isLoading ? "..." : strings.loginButton}
                    </LoginButton>
                    <TermsRow>
                        {strings.accountAgreement}
                        <Link to="/terms-of-service">
                            {strings.termsOfService}
                        </Link>
                        &nbsp;&nbsp;{config.locale === "es" ? "y" : "and"}
                        <Link to="/privacy-policy">
                            {strings.privacyPolicy}
                        </Link>
                    </TermsRow>
                </SignUpContainer>
            )}
        </SignUpController>
    );
};

export type SignUpFormStrings = {
    facebookButton: string;
    googleButton: string;
    loginButton: string;
    spearatorText: string;
    title: string;
    footerText: string;
    loginLinkText: string;
    emailCheckbox: string;
    termsOfService: string;
    privacyPolicy: string;
    accountAgreement: string;
} & SignUpStrings;

export const SignUpFooter: React.FunctionComponent<{
    onClick?: () => void;
    darkMode?: boolean;
    link?: string;
}> = ({ onClick, link = "/login", darkMode }) => {
    const strings = useStrings<SignUpFormStrings>("signUpPage");
    return (
        <SignUpRow darkMode={darkMode}>
            {strings.footerText}
            <Link
                to={link}
                onClick={event => {
                    if (onClick) {
                        event.preventDefault();
                        onClick();
                    }
                }}
            >
                {strings.loginLinkText}
            </Link>
        </SignUpRow>
    );
};
