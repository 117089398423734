import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Input } from "@fitplan/lib/components/Input";
import { ChevronBanner } from "@fitplan/lib/components/ChevronBanner";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { WebImage } from "@fitplan/lib/components/WebImage";
import { useConfig } from "@fitplan/context/lib-es/config";
import { IGiftCard } from "@fitplan/lib/types/stripe";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import { VerifyGiftCard } from "../../components/RedeemGiftCard/VerifyGiftCard";
import { RedeemGiftCard } from "../../components/RedeemGiftCard";
import { AuthFormStrings } from "../../components/strings/AuthStrings";
import Modal from "../../components/modal/Modal";
import { useStaticQuery, graphql } from "gatsby";

interface Props {}

export interface GiftCardStrings {
    title: string;
    subtitle: string;
    bannerSrc: string;
    bannerMobileSrc?: string;
}

const GiftCardRedeemPage: React.FunctionComponent<Props> = ({ ...props }) => {
    const { imageBaseUrl } = useConfig();
    const data = useStaticQuery(graphql`
        query GiftCardRedeemPage {
            startPage {
                success
                button
                exit
            }
        }
    `);
    const [giftCard, setGiftCard] = React.useState<IGiftCard | null>(null);
    const strings: GiftCardStrings = {
        subtitle: "The Fitplan",
        title: "Gift Card",
        bannerSrc: `${imageBaseUrl}/web/giftcard/redeem_hero.png?tr=w-1400`,
    };

    return (
        <PageWrapper {...props}>
            <AuthFormStrings data={data}>
                <Container>
                    <ChevronBanner
                        subtitle={strings.subtitle}
                        src={
                            "https://images.fitplanapp.com/web/giftcard/hero_2.jpg"
                        }
                        title={strings.title}
                        button={strings.bannerMobileSrc}
                        polygonColor="#1d222b"
                    />
                    {!giftCard && (
                        <VerifyGiftCard
                            onVerified={giftCard => setGiftCard(giftCard)}
                        />
                    )}
                    {giftCard && (
                        <Modal open={true}>
                            <RedeemGiftCard giftcard={giftCard} />
                        </Modal>
                    )}
                    <Polygon polygonColor="#111213" borderColor="#00ffb3" />
                </Container>
            </AuthFormStrings>
        </PageWrapper>
    );
};

const Container = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #1d222b;
`;

export default GiftCardRedeemPage;
