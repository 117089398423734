import * as React from "react";
import ReactDOM from "react-dom";
import { css } from "@emotion/core";

export interface Props {
    open?: boolean;
    nodeName?: string;
}

const Modal: React.SFC<Props> = ({
    open = false,
    nodeName = "BODY",
    children,
}) => (
    <>
        {open &&
            ReactDOM.createPortal(
                <div
                    css={css`
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100vw;
                        height: 100%;
                        background-color: #000000;
                        color: #000000;
                        z-index: 10000;
                        overflow: auto;
                    `}
                >
                    {children}
                </div>,
                document.getElementsByTagName(nodeName)[0]
            )}
    </>
);

export default Modal;
