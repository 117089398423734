import { IStripePlan, IStripePlanDisplay, IStripeCoupon } from "../types";
import { IUser } from "@fitplan/context/lib-es/auth/types";
import format from "date-fns/format";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import isAfter from "date-fns/isAfter";

export const processStripePlan: (
    plan: IStripePlan
) => IStripePlanDisplay = plan => {
    // Main display text for payment frequency
    let intervalDisplayText = "Quarterly";
    let intervalDisplaySubText = "Billed once per quarter";
    let chargedFrequencyDisplay = "quarterly";
    const intervalCount = plan.interval_count || (plan as any).intervalCount;
    if (plan.interval === "year") {
        intervalDisplayText = "Yearly";
        intervalDisplaySubText = "Billed once per year";
        chargedFrequencyDisplay = "anually";
    } else if (plan.interval === "month" && intervalCount === 1) {
        intervalDisplayText = "Monthly";
        intervalDisplaySubText = "Billed once a month";
        chargedFrequencyDisplay = "monthly";
    }

    return {
        ...plan,
        planId: plan.planId || plan.id,
        id: plan.planId || plan.id,
        priceDisplayText: `$${plan.amount / 100}`,
        chargedFrequencyDisplay: chargedFrequencyDisplay,
        intervalDisplayText: intervalDisplayText,
        intervalDisplaySubText: intervalDisplaySubText,
    };
};

export const getDollars = (
    cents: number,
    currency: string,
    discount: { percentOff?: number; amountOff?: number },
    language?: "en" | "es"
): string => {
    let discountAmount;
    if (discount) {
        discountAmount = discount.percentOff
            ? cents * (discount.percentOff / 100)
            : discount.amountOff;
    }
    let num = (discountAmount ? cents - discountAmount : cents) / 100;
    if (discount && num > 50 && num < 50.49) {
        num = 50;
    }
    return num <= 0
        ? language === "es"
            ? "GRATIS"
            : "FREE"
        : num.toLocaleString("en-US", {
              style: "currency",
              currency: currency.toUpperCase(),
          });
};

export const getCouponDiscountDisplay = (coupon: IStripeCoupon): string => {
    const { duration, durationInMonths, percentOff, amountOff } = coupon;
    if (duration === "once") {
        return amountOff ? `${amountOff / 100} USD OFF` : `${percentOff}% OFF`;
    }
    if (duration === "repeating" && durationInMonths) {
        return amountOff
            ? `${amountOff / 100} USD OFF FOR ${durationInMonths} MONTHS`
            : `${percentOff}% OFF FOR ${durationInMonths} MONTHS`;
    }
    if (duration === "forever") {
        return amountOff
            ? `${amountOff / 100} USD OFF FOREVER`
            : `${percentOff}% OFF FOREVER`;
    }
    return "";
};

export const getAmountDueAfterTrial = (
    selectedPlan: IStripePlanDisplay,
    coupon: IStripeCoupon | null | undefined,
    language: "en" | "es"
): string => {
    const {
        amount: rawAmount,
        currency,
        intervalDisplayText,
        realAmount,
    } = selectedPlan;
    const amount = realAmount || rawAmount;
    const { percentOff, amountOff, durationInMonths } = coupon || {};
    if (durationInMonths && intervalDisplayText.toLowerCase() !== "monthly") {
        return `$${amount / 100}`;
    }
    return `${getDollars(
        amount,
        currency,
        {
            percentOff,
            amountOff,
        },
        language
    )}`;
};

export const getPaymentRequestAmount = (
    selectedPlan: IStripePlanDisplay,
    coupon?: IStripeCoupon | null
): number => {
    const { amount, intervalDisplayText } = selectedPlan;
    const { durationInMonths } = coupon || {};
    if (durationInMonths && intervalDisplayText.toLowerCase() !== "monthly") {
        return amount;
    }
    let discountAmount;
    if (coupon) {
        discountAmount = coupon.percentOff
            ? amount * (coupon.percentOff / 100)
            : coupon.amountOff;
    }
    const num = Math.floor(discountAmount ? amount - discountAmount : amount);
    return num <= 0 ? 0 : num;
};

export const getExpirationString = (
    user: IUser,
    language: "en" | "es"
): string => {
    if (language === "es") {
    }
    let expirationDate = new Date(user.displayExpirationTimeStamp);
    let latestGiftCard = null;
    for (const giftCard of user.giftCards) {
        const giftCardDate = new Date(giftCard.expirationDate);
        if (!latestGiftCard || isAfter(giftCardDate, latestGiftCard)) {
            latestGiftCard = giftCardDate;
        }
    }
    const daysDiff = differenceInCalendarDays(expirationDate, new Date());
    const expirationString = format(expirationDate, "MMMM d, yyyy");
    if (daysDiff < 0) {
        return `Your subscription expired on ${expirationString}`;
    }
    if (user.cancelAtPeriodEnd) {
        return `Your subscription expires on ${expirationString}`;
    }
    return `Your subscription renews on ${expirationString}`;
};
