import * as React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import { Link } from "../Link";
import { IUser } from "@fitplan/context/lib-es/auth/types";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTatari } from "../../analytics/useTatari";
import { LogInController, LoginStrings } from "./LogInController";
import {
    Container,
    Row,
    SeparatorRow,
    Separator,
    SeparatorText,
    Input,
    Title,
    ErrorRow,
    ForgotPasswordRow,
    SignUpRow,
    AppleSignInContainer,
} from "./layout";
import { SSOButton, LoginButton } from "./Button";
import { GoogleIcon, FacebookIcon } from "./icons";
import { useStrings } from "@fitplan/context/lib-es/strings";

export interface Props {
    onComplete: (user: IUser) => void;
    darkMode?: boolean;
    redirectUri?: string;
}

export const LoginForm: React.SFC<Props> = ({
    darkMode,
    onComplete,
    redirectUri,
}) => {
    const config = useConfig();
    const strings = useStrings<LoginFormStrings>("loginPage");
    const { tatariIdentify, tatariTrack } = useTatari();

    return (
        <LogInController
            onComplete={user => {
                tatariIdentify(user.userId);
                tatariTrack("login", { userId: user.userId });
                onComplete(user);
            }}
            redirectUri={redirectUri}
        >
            {({
                email,
                password,
                onSubmit,
                globalError,
                isLoading,
                submitDisabled,
                facebookLogin,
                googleLogin,
            }) => (
                <Container darkMode={darkMode} onSubmit={onSubmit}>
                    {strings.title && <Row>
                        <Title>{strings.title}</Title>
                    </Row>}
                    <Row>
                        <FacebookLogin
                            appId={config.facebookAppId}
                            autoLoad={false}
                            fields="email"
                            authType={"reauthenticate"}
                            disableMobileRedirect={true}
                            render={(props: any) => (
                                <SSOButton
                                    colors="dark"
                                    icon={<FacebookIcon />}
                                    onClick={props.onClick}
                                >
                                    {strings.facebookButton}
                                </SSOButton>
                            )}
                            {...facebookLogin}
                        />
                        <GoogleLogin
                            clientId={config.googleApiClientId}
                            cookiePolicy={"single_host_origin"}
                            render={props => (
                                <SSOButton
                                    colors="light"
                                    icon={<GoogleIcon />}
                                    onClick={props.onClick}
                                >
                                    {strings.googleButton}
                                </SSOButton>
                            )}
                            {...googleLogin}
                        />
                        <AppleSignInContainer
                            id="appleid-signin"
                            data-color="black"
                            data-border="true"
                            data-type="sign in"
                        />
                    </Row>
                    <SeparatorRow>
                        <Separator />
                        <SeparatorText>{strings.spearatorText}</SeparatorText>
                        <Separator />
                    </SeparatorRow>
                    <Row>
                        <Input
                            darkMode={darkMode}
                            name="username"
                            type="email"
                            {...email}
                        />
                        <Input
                            darkMode={darkMode}
                            name="password"
                            type="password"
                            {...password}
                        />
                    </Row>
                    <ErrorRow>{globalError}</ErrorRow>
                    <LoginButton
                        disabled={submitDisabled}
                        type="submit"
                        onClick={onSubmit}
                    >
                        {isLoading ? "..." : strings.loginButton}
                    </LoginButton>
                    <ForgotPasswordRow>
                        <Link to="/forgot-password">
                            {strings.forgotPasswordLinkText}
                        </Link>
                    </ForgotPasswordRow>
                </Container>
            )}
        </LogInController>
    );
};

export type LoginFormStrings = {
    facebookButton: string;
    googleButton: string;
    loginButton: string;
    spearatorText: string;
    title: string;
    footerText: string;
    signUpLinkText: string;
    forgotPasswordLinkText: string;
} & LoginStrings;

export const LoginFooter: React.FunctionComponent<{
    onClick?: () => void;
    darkMode?: boolean;
    link?: string;
}> = ({ onClick, link = "/signup", darkMode }) => {
    const strings = useStrings<LoginFormStrings>("loginPage");
    return (
        <SignUpRow darkMode={darkMode}>
            {strings.footerText}
            <Link
                to={link}
                onClick={event => {
                    if (onClick) {
                        event.preventDefault();
                        onClick();
                    }
                }}
            >
                {strings.signUpLinkText}
            </Link>
        </SignUpRow>
    );
};
